import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Selling Made Simple",
          "Elevate Your Listings",
          "Stand Out in the Market",
          "Turn Views into Visits",
        ],
        autoStart: true,
        loop: true,
        delay: 50,
        deleteSpeed: 40,
      }}
    />
  );
}

export default Type;
