import React, { Component } from 'react';
import Chart from 'react-google-charts';

const BarData = [
  ['x', 'No Video', 'Listing Video'],
  [0, 0, 0],
  [1, 100, 403],
];

const BarChartOptions = {
  hAxis: {
    title: 'Time',
    gridlines: {
      color: 'transparent'
    }
  },
  vAxis: {
    title: 'Inquiries %',
    // gridlines: {
    //   color: 'transparent'
    // }
  },
  backgroundColor: { fill:'transparent' },
};

export default class Barchart extends Component {
  render() {
    return (
      <div className="container mt-5">
        <h2>How to Create Bar Chart in React Application</h2>
        <Chart
          width={'700px'}
          height={'410px'}
          chartType="ColumnChart" // Change chartType to 'ColumnChart' for bar chart
          loader={<div>Loading Chart</div>}
          data={BarData}
          options={BarChartOptions}
          rootProps={{ 'data-testid': '2' }}
        />
      </div>
    );
  }
}
