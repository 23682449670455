import React from "react"
import { AiOutlineCheck, AiOutlineStop, AiOutlineFire } from "react-icons/ai";

function Table() {
    return (
        <div>
            <h1>You <strong className="purple">Sell Properties</strong> Quicker </h1>

            <table className="table">
            <thead>
            <tr>
                <th><strong className="purple">Basic Photo</strong></th>
                <th><strong className="purple">Photo & Video Pro</strong></th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td><AiOutlineCheck/> Interior Photos</td>
                <td><AiOutlineCheck/> Interior Photos</td>
                
            </tr>
            <tr>
                <td><AiOutlineCheck/> Exterior Photos</td>
                <td><AiOutlineCheck/> Exterior Photos</td>
                
            </tr>
            <tr>
                <td><AiOutlineCheck/> Unlimited Aerial Photos</td>
                <td><AiOutlineCheck/> Unlimited Aerial Photos</td>
                
            </tr>
            <tr>
                <td><AiOutlineStop className="red-icon" /> 1-2 Minute Listing Video</td>
                <td><AiOutlineCheck/> 1-2 Minute Listing Video</td>
                
            </tr>
            <tr>
                <td><AiOutlineStop className="red-icon" /> Video Drone Coverage</td>
                <td><AiOutlineCheck/> Video Drone Coverage</td>
            </tr>
            <tr>
                <td><AiOutlineStop className="red-icon" /> 30-40s Social Reel</td>
                <td><AiOutlineCheck/> 30-40s Social Reel</td>
            </tr>
            <tr>
                <td></td>
                <td><p2 style={{fontSize: "65%", margin: "50px 0 0"}}>FREE</p2> Twilight Photography </td>
            </tr>
            <tr>
                <td>$175</td>
                <td><AiOutlineFire/> $375 <p2 style={{fontSize: "80%", textDecoration: "line-through"}}>$450</p2></td>
            </tr>
            </tbody>
        </table>    

        </div>
    )
}

export default Table;