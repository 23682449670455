import React from "react";
import { AiOutlineMail, AiOutlinePhone, AiFillInstagram } from "react-icons/ai";
import { FaFacebookMessenger } from "react-icons/fa";

function Contact(){
    return (
        <div>
            <h1 style={{ marginTop: "40px" }}>
                <strong className="purple">Contact</strong> Us
            </h1>

            <ul className="home-about-social-links">
                <li className="social-icons">
                <a
                    href="tel:+19154007796"
                    target="_blank"
                    rel="noreferrer"
                    className="icon-colour home-social-icons"
                >
                    <AiOutlinePhone />
                </a>
                </li>
                <li className="social-icons">
                <a
                    href="mailto:contact@odaproductions.net"
                    target="_blank"
                    rel="noreferrer"
                    className="icon-colour home-social-icons"
                >
                    <AiOutlineMail />
                </a>
                </li>
            </ul>
            <ul className="home-about-social-links">
                <li className="social-icons">
                <a
                    href="https://m.me/odaproductions915"
                    target="_blank"
                    rel="noreferrer"
                    className="icon-colour home-social-icons"
                >
                    <FaFacebookMessenger />
                </a>
                </li>
                <li className="social-icons">
                <a
                    href="https://instagram.com/odaproductions"
                    target="_blank"
                    rel="noreferrer"
                    className="icon-colour home-social-icons"
                >
                    <AiFillInstagram />
                </a>
                </li>
            </ul>
        </div>
    )
}

export default Contact;